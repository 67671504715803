import React, { useState, useEffect } from "react";

import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Tag, Row, Col, Select, Progress, Statistic, Card } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import Filter from "../components/filter/Filter";
import UserTable from "../components/tables/pageTables/UserTable";

import { User as DataType } from "../interfaces/adminInterfaces";
import SupplierOrdersTable from "../components/tables/pageTables/SupplierOrdersTable";

const SupplierOrders: React.FC = () => {


  // Kartların ortak stili
  const commonCardStyle = {
    minHeight: "160px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    padding: "16px",
  };

  // Ortak "içerik kabı" stili
  const contentWrapperStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  };

  const { Option } = Select;
  const months = [
    { value: 0, label: "Tümü", year: 0 },
    { value: 10, label: "Ekim 2024", year: 2024 },
    { value: 11, label: "Kasım 2024", year: 2024 },
    { value: 12, label: "Aralık 2024", year: 2024 },
    { value: 1, label: "Ocak 2025", year: 2025 },
    { value: 2, label: "Şubat 2025", year: 2025 },
    { value: 3, label: "Mart 2025", year: 2025 },
    { value: 4, label: "Nisan 2025", year: 2025 },
  ];

  const [current, setCurrent] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [users, setUsers] = useState<DataType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [supplierId, setSupplierId] = useState<number>(0);
  const [summaries, setSummaries] = useState<any[]>([]);
  const [walletInfos, setWalletInfos] = useState<any[]>([]);
  const [sellerShopId, setSellerShopId] = useState<number>(0);
  const [progressCards, setProgressCards] = useState<any[]>( [
    {
      id: 1,
      title: "Aylık Satış Hedefi",
      current: 0,
      target: 7250,
      storeSales: 0,
      marketPlaceSales: 0,
      color: "blue",
    },
    {
      id: 2,
      title: "Yıllık Satış Hedefi",
      current: 0,
      storeSales: 0,
      marketPlaceSales: 0,
      target: 500000,
      color: "green",
    },
  ]);
  const [monthlyTargets, setMonthlyTargets] = useState<any[]>([
    { month: "Ocak", current: 0, target: 7200 },
    { month: "Şubat", current: 0, target: 8925 },
    { month: "Mart", current: 0, target: 12000 },
    { month: "Nisan", current: 0, target: 16875 },
    { month: "Mayıs", current: 0, target: 16500 },
    { month: "Haziran", current: 0, target: 20625 },
    { month: "Temmuz", current: 0, target: 26280 },
    { month: "Ağustos", current: 0, target: 33150 },
    { month: "Eylül", current: 0, target: 42000 },
    { month: "Ekim", current: 0, target: 67500 },
    { month: "Kasım", current: 0, target: 96000 },
    { month: "Aralık", current: 0, target: 153000 },
]);
  const [sellerShopIds, setSellerShopIds] = useState<any[]>([
    { id: 0, name: "Seller Shop" },
  ]);
  const [infoCards, setInfoCards] = useState<any[]>([]);

  const [month, setMonth] = useState<number>(2);
  const [year, setYear] = useState<number>(2025);
  const [graphData, setGraphData] = useState<any[]>([
    { order_day: "2024-08-29", number: 2.7 },
    { order_day: "2024-08-30", number: 2.7 },
    { order_day: "2024-08-31", number: 0.0 },
    { order_day: "2024-09-01", number: 6.9 },
    { order_day: "2024-09-02", number: 6.2 },
    { order_day: "2024-09-03", number: 0.0 },
    { order_day: "2024-09-04", number: 2.1 },
    { order_day: "2024-09-05", number: 4.0 },
    { order_day: "2024-09-06", number: 1.6 },
    { order_day: "2024-09-07", number: 4.8 },
    { order_day: "2024-09-08", number: 2.7 },
    { order_day: "2024-09-09", number: 5.6 },
    { order_day: "2024-09-10", number: 10.0 },
    { order_day: "2024-09-11", number: 7.3 },
    { order_day: "2024-09-12", number: 12.1 },
    { order_day: "2024-09-13", number: 8.6 },
    { order_day: "2024-09-14", number: 0.0 },
    { order_day: "2024-09-15", number: 9.2 },
    { order_day: "2024-09-16", number: 4.2 },
    { order_day: "2024-09-17", number: -8.1 },
    { order_day: "2024-09-18", number: 21.6 },
    { order_day: "2024-09-19", number: 33.7 },
    { order_day: "2024-09-20", number: 24.3 },
    { order_day: "2024-09-21", number: 36.1 },
    { order_day: "2024-09-22", number: 32.2 },
    { order_day: "2024-09-23", number: 22.1 },
    { order_day: "2024-09-24", number: 36.6 },
    { order_day: "2024-09-25", number: 41.5 },
    { order_day: "2024-09-26", number: 37.0 },
    { order_day: "2024-09-27", number: 74.0 },
    { order_day: "2024-09-28", number: 52.3 },
  ]);

  //fetch all users
  const getSupplierSaleSummary = (
    supplierId: number,
    month: number,
    year: number,
    sellerShopId: number
  ) => {
    setLoading(true);
    AdminServices.getSupplierSalesSummary(supplierId, month, year, sellerShopId)
      .then((res) => {
        setSummaries(res.data.sales);

        let totalProfit = 0;
        let totalProductCost = 0;
        let totalCargoCost = 0;
        let totalRexvenRevenue = 0;
        let totalSellerRevenue = 0;
        let totalDesignerRevenue = 0;
        let totalOrderItem = 0;
        let totalSupplierOrder = 0;
        let totalSellerOrder = 0;

        res.data.sales.map((item: any) => {
          totalProfit += Number(item.profit_sum);
          totalProductCost += Number(item.total_sum);
          totalCargoCost += Number(item.shipment_cost_sum);
          totalRexvenRevenue += Number(item.commission_price_sum);
          totalSellerRevenue += Number(item.seller_order_total);
          totalDesignerRevenue += Number(item.design_price_sum);
          totalOrderItem += Number(item.order_item_count_sum);
          totalSupplierOrder += Number(item.supplier_order_count);
          totalSellerOrder += Number(item.seller_orders_count);
        
        });

        let totalProfitPerProduct = totalProfit / totalOrderItem;

        setInfoCards([
          { name: "Profit", value: totalProfit.toFixed(0) },
          { name: "Product Cost", value: totalProductCost.toFixed(0) },
          { name: "Seller Order", value: totalSellerOrder },
          { name: "Revenue", value: totalRexvenRevenue.toFixed(0) },

          {
            name: "Supplier Wallet",
            value: Number(res.data.supplierWallet[0].sum).toFixed(0),
          },
          { name: "Shops", value: res.data.sellerShops[0].count },

          {
            name: `Resends ( ${
              Number(res.data.resendFaultyManufacture[0].sum) +
              Number(res.data.resendOther[0].sum)
            })`,
            value: `Ür.:${res.data.resendFaultyManufacture[0].sum}
             Sat.: ${res.data.resendOther[0].sum} `,
          },
          {
            name: "Product/Order",
            value: (Number(totalOrderItem) / Number(totalSellerOrder)).toFixed(
              2
            ),
          }, // Getirilecek

          {
            name: "Profit Per Product",
            value: totalProfitPerProduct.toFixed(2),
          },
          { name: "Cargo Cost", value: totalCargoCost.toFixed(0) },

          { name: "Products", value: totalOrderItem },

          {
            name: "Avg. Products",
            value: (Number(totalOrderItem) / res.data.sales.length).toFixed(0),
          }, // Getirilecek

          {
            name: "Seller Wallet",
            value: Number(res.data.sellerWallet[0].sum).toFixed(0),
          },
          { name: "Seller Revenue", value: totalSellerRevenue.toFixed(0) },

          {
            name: "Kar Marjı",
            value: (Number(totalProfit) / Number(totalRexvenRevenue)).toFixed(
              3
            ),
          },
          {
            name: "Avg. Revenue",
            value: (
              Number(totalRexvenRevenue) / Number(totalOrderItem)
            ).toFixed(0),
          },
        ]);

        setTotalPageCount(1);
        let graphDataDb = res.data.sales.map((item: any) => ({
          order_day: item.order_day,
          number: item.profit_sum,
        }));
        graphDataDb = graphDataDb.filter(
          (item: any) => new Date(item.order_day) >= new Date("2024-09-28")
        );
        // concat graphDataDb with graphData
        graphDataDb.reverse();
        // console.log(graphDataDb, "graph");
        let allData = [...graphData, ...graphDataDb];
        // sort with order_day
        allData.sort(
          (a, b) =>
            new Date(a.order_day).getTime() - new Date(b.order_day).getTime()
        );
        setGraphData([...graphData, ...graphDataDb]);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
    AdminServices.getSuppliersPaymentInfos()
      .then((res) => {
        setWalletInfos(res.data);
        //console.log(res.data);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      });

    AdminServices.getSellerShops()
      .then((res) => {
     //   console.log(res.data);
        setSellerShopIds(res.data);
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMonthChange = (value: number) => {
    setMonth(value);
    let selectedYear = months.filter((i) => i.value === value)[0].year;
    setYear(selectedYear);
    getSupplierSaleSummary(supplierId, value, selectedYear, sellerShopId);
  };

  const handleSellerShopIdChange = (value: number) => {
    setSellerShopId(value);
    getSupplierSaleSummary(supplierId, month, year, value);
  };

  const handleSupplierIdChange = (value: number) => {
    setSupplierId(value);
    getSupplierSaleSummary(value, month, year, sellerShopId);
  };

  const getTargets = () => {
    AdminServices.getTargets().then((res:any) => {
     console.log(res.data)
     setMonthlyTargets(res.data.monthlyTargets)
     setProgressCards(res.data.progressCards)
    });
  };

  useEffect(() => {
    getSupplierSaleSummary(supplierId, month, year, sellerShopId);
    getTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        {progressCards.map((card) => {
          const percent = ((card.current / card.target) * 100).toFixed(1);
          const isYearly = card.id === 2; // Yıllık kart mı?

          return (
            <Col xs={24} sm={12} key={card.id}>
              <Card
                bordered={false}
                style={{ ...commonCardStyle, minHeight: 200 }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* Sol kısım */}
                  <div>
                    <Statistic
                      title={
                        <span style={{ fontSize: "1.1rem", color: "#888" }}>
                          {card.title}
                        </span>
                      }
                      value={`${percent}%`}
                      valueStyle={{
                        color: card.color,
                        fontSize: "2rem",
                        fontWeight: 700,
                      }}
                    />
                    
                    <div style={{ display: 'flex', gap: '16px', marginTop: '1rem' }}>
                      <Statistic
                        title={<span style={{ color: "#444" }}>Toplam Satış</span>}
                        value={`${card.current}`}
                        valueStyle={{
                          fontSize: "1.2rem",
                          color: "#444",
                          fontWeight: 600,
                          marginTop: "0.5rem",
                        }}
                      />
                      <Statistic
                        title={<span style={{fontSize: "0.7rem", color: "#888" }}>Musteriler </span>}
                        value={`${card.marketPlaceSales}`}
                        valueStyle={{
                          fontSize: "0.7rem",
                          color: "#888",
                          fontWeight: 600,
                          marginTop: "0.5rem",
                        }}
                      />
                        <Statistic
                        title={<span style={{ fontSize: "0.7rem",color: "#888" }}>Magazalar</span>}
                        value={`${card.storeSales}`}
                        valueStyle={{
                          fontSize: "0.7rem",
                          color: "#888",
                          fontWeight: 600,
                          marginTop: "0.5rem",
                        }}
                      />
                    </div>
                  </div>

                  {/* Sağ kısım (HEDEF) */}
                  <div style={{ textAlign: "right" }}>
                    <div
                      style={{
                        fontSize: "clamp(1.1rem, 4vw, 2.5rem)", // Responsive font size
                        fontWeight: 900,
                        color: "#fa8c16",
                        lineHeight: 1,
                        paddingLeft: "0px", // Add some padding
                        marginRight: "-8px" // Negative margin to pull it in slightly
                      }}
                    >
                      {Number(card.target).toLocaleString("en-US")}
                    </div>
                    <div
                      style={{ 
                        fontSize: "clamp(0.8rem, 2vw, 1rem)", // Responsive font size
                        color: "#888", 
                        marginTop: 4,
                        paddingLeft: "8px" // Match padding with above
                      }}
                    >
                      Hedef
                    </div>
                  </div>
                </div>

                {/* Progress Bar */}
                <Progress
                  percent={Number(percent)}
                  strokeColor={card.color}
                  status="active"
                  style={{ marginTop: "16px" }}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
      {/* ALT KISIM: 12 Aylık Ufak Kartlar (2 satır, her satırda 6 kart) */}

      <Row gutter={[8, 8]}>
        {" "}
        {/* Gutter'ı 16 yerine 8 yaptık */}
        {monthlyTargets.slice(0, 6).map((m, index) => {
          const progressValue = Math.round((m.current / m.target) * 100);

          return (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={8}
              lg={4}
              xl={4}
              style={{ marginBottom: "16px" }}
            >
              <Card
                bordered={false}
                style={{
                  ...commonCardStyle,
                  minHeight: "140px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {/* Ay Adı */}
                <h4
                  style={{
                    fontSize: "1rem",
                    color: "#555",
                    marginBottom: 4, // Margin'i biraz azalttık
                  }}
                >
                  {m.month}
                </h4>

                {/* current/target bilgisi */}
                <div
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: 700,
                    color: "#333",
                    marginBottom: 2, // Aralıkları biraz kısıyoruz
                  }}
                >
                  {m.current}/{m.target}
                </div>

                {/* Yüzdelik */}
                <div
                  style={{ fontSize: "0.9rem", color: "#888", marginBottom: 4 }}
                >
                  {progressValue}%
                </div>

                {/* Progress bar */}
                <Progress
                  percent={progressValue > 100 ? 100 : progressValue}
                  size="small"
                  showInfo={false}
                  strokeColor={progressValue >= 100 ? "#52c41a" : "#1890ff"}
                  style={{ marginBottom: 4 }}
                />

                {/* İkon alanı */}
                <div style={{ height: "20px" }}>
                  {progressValue >= 100 ? (
                    <CheckCircleOutlined
                      style={{
                        color: "#52c41a",
                        fontSize: "1.2rem",
                      }}
                    />
                  ) : (
                    // Koşan adam emojisi veya istediğiniz herhangi bir ikon
                    <span
                      role="img"
                      aria-label="running"
                      style={{
                        fontSize: "1.2rem",
                        transform: "scaleX(-1)", // Emojiyi yatay eksende ters çevirir
                        display: "inline-block", // Transform'un düzgün çalışması için
                      }}
                    >
                      🏃
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Row gutter={[8, 8]}>
        {" "}
        {/* Gutter'ı 16 yerine 8 yaptık */}
        {monthlyTargets.slice(6).map((m, index) => {
          const progressValue = Math.round((m.current / m.target) * 100);

          return (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={8}
              lg={4}
              xl={4}
              style={{ marginBottom: "16px" }}
            >
              <Card
                bordered={false}
                style={{
                  ...commonCardStyle,
                  minHeight: "140px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {/* Ay Adı */}
                <h4
                  style={{
                    fontSize: "1rem",
                    color: "#555",
                    marginBottom: 4, // Margin'i biraz azalttık
                  }}
                >
                  {m.month}
                </h4>

                {/* current/target bilgisi */}
                <div
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: 700,
                    color: "#333",
                    marginBottom: 2, // Aralıkları biraz kısıyoruz
                  }}
                >
                  {m.current}/{m.target}
                </div>

                {/* Yüzdelik */}
                <div
                  style={{ fontSize: "0.9rem", color: "#888", marginBottom: 4 }}
                >
                  {progressValue}%
                </div>

                {/* Progress bar */}
                <Progress
                  percent={progressValue > 100 ? 100 : progressValue}
                  size="small"
                  showInfo={false}
                  strokeColor={progressValue >= 100 ? "#52c41a" : "#1890ff"}
                  style={{ marginBottom: 4 }}
                />

                {/* İkon alanı */}
                <div style={{ height: "20px" }}>
                  {progressValue >= 100 ? (
                    <CheckCircleOutlined
                      style={{
                        color: "#52c41a",
                        fontSize: "1.2rem",
                      }}
                    />
                  ) : (
                    // Koşan adam emojisi veya istediğiniz herhangi bir ikon
                    <span
                      role="img"
                      aria-label="running"
                      style={{
                        fontSize: "1.2rem",
                        transform: "scaleX(-1)", // Emojiyi yatay eksende ters çevirir
                        display: "inline-block", // Transform'un düzgün çalışması için
                      }}
                    >
                      🏃
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        {walletInfos.map((walletInfo, index) => (
          <Col key={index}>
            <Tag color="blue">
              <strong>{walletInfo.name} : </strong>
              <Tag color="green">
                {Number(walletInfo.balance).toFixed(2)} USD
              </Tag>
            </Tag>
          </Col>
        ))}
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        {infoCards.map((info, index) => (
          <Col key={index} xs={24} sm={12} md={6} lg={3}>
            <div
              style={{
                backgroundColor: "#f0f2f5",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
                transition: "all 0.3s ease",
                // hover: { transform: "scale(1.05)" }
              }}
            >
              <h4 style={{ color: "#001529", marginBottom: "10px" }}>
                {info.name}
              </h4>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#1890ff",
                }}
              >
                {info.name !== "Total Order Item" &&
                info.name !== "Total Supplier Order" &&
                info.name !== "Profit Per Product" &&
                info.name !== "Seller Order" &&
                !info.name.includes("Resends") &&
                info.name !== "Products" &&
                info.name !== "Shops" &&
                info.name !== "Avg. Products" &&
                info.name !== "Kar Marjı"
                  ? Number(info.value) + " USD"
                  : info.value}
              </p>
            </div>
          </Col>
        ))}
      </Row>

      {/* Filters */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col>
          <Select
            placeholder="Select Month"
            style={{ width: 120 }}
            onChange={handleMonthChange}
            value={month}
          >
            {months.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>

        <Col>
          <Select
            placeholder="Select Seller Shop"
            style={{ width: 180 }}
            onChange={handleSellerShopIdChange}
            value={sellerShopId}
          >
            {sellerShopIds.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            placeholder="Select Supplier Shop"
            style={{ width: 180 }}
            onChange={handleSupplierIdChange}
            value={supplierId}
          >
            {walletInfos.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <SupplierOrdersTable
        datas={summaries}
        loading={loading}
        setCurrent={setCurrent}
        current={current}
        totalPageCount={totalPageCount}
      />
    </>
  );
};

export default SupplierOrders;
